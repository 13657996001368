<template>
  <b-card class="card-search card-shadow mb-4">
    <b-row>
      <b-col md="3" class="text-center">
        <b-img :src="data.mutuelle.logo" fluid alt="Responsive image" style="max-height:72px"></b-img>
        <b-form-rating style="font-size:1.5rem" no-border v-model="data.mutuelle.rating" variant="warning" size="lg" class="mt-5 mb-3" readonly></b-form-rating>
      </b-col>
      <b-col md="7">
        <b-row class="">
          <b-col md="6" v-for="(t,i) in tmp" :key="i" class="mt-5">
            <label :class="`text-${i % 2 ? 'secondary' : 'primary'}`"><i :class="`text-${i % 2 ? 'secondary' : 'primary'} ${t.icon}`"></i> <span class="h6 font-weight-bold">{{t.titre}}</span></label>
            <b-form-rating :variant="i % 2 ? 'secondary' : 'primary'" icon-empty="circle"  icon-full="circle-fill" v-model="t.value" no-border size="sm" readonly></b-form-rating>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="2" class="text-center">
        <b-button size="lg" variant="primary" style="margin-top:4em">{{data.tarif}}€</b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  name: "DetailList",
  props: ['data'],
  data: () => ({
    tmp:[
      {
        titre:"Soins médicaux",
        icon:"fas fa-first-aid",
        value:2
      },
      {
        titre:"Dentaire",
        icon:"fas fa-tooth",
        value:3
      },
      {
        titre:"Optique",
        icon:"fas fa-glasses",
        value:4
      },
      {
        titre:"Hospitalisation",
        icon:"fas fa-hospital-user",
        value:5
      }
    ],
  })
}
</script>
<style lang="scss" scoped>
</style>
