<template>
  <b-card class="mb-4 card-search card-shadow" img-top>
    <div class="text-center">
      <b-img :src="data.mutuelle.logo" fluid alt="Responsive image" style="height:80px"></b-img>
      <b-card-title>{{data.titre}}</b-card-title>
    </div>
    <b-card-text>
      {{data.mutuelle.description}}
    </b-card-text>
    <div class="text-center">
      <b-form-rating no-border v-model="data.mutuelle.rating" variant="warning" size="lg" class="mt-5 mb-3" readonly></b-form-rating>
      <b-button size="lg" variant="primary">{{data.tarif}}€</b-button>
    </div>
  </b-card>
</template>
<script>
export default {
  name: "DetailList",
  props: ['data'],
  data: () => ({

  })
}
</script>
<style scoped>
.card-img-top{
  height:100px;
  max-width: 250px;
  text-align: center !important;
}
</style>
