<template>
  <b-row v-if="params != false ">
    <b-col md="3">
      <b-card class="card-search card-shadow mt-4 sticky-top filter">
        <b-row>
          <b-col cols="12">
            <p class="h5 font-weight-bold">Filtre selon</p>
          </b-col>
          <b-col cols="12" class="mt-5">
            <vs-select state="primary" multiple filter placeholder="Filtre les mutuelles" v-model="filters['mutuelle_id']">
              <template #icon>
                <i class="fas fa-birthday-cake text-primary"></i>
              </template>
              <vs-option v-for="(m, i) in params.Mutuelle" :key="i" :label="m.titre" :value="m.id">
                {{m.titre}}
              </vs-option>
            </vs-select>
          </b-col>
          <b-col cols="12" class="mt-5">
            <span class="font-weight-bold">Prix total ({{filters['min_tarif']}}€ - {{filters['max_tarif']}}€)</span><br>
            <veeno class="mt-2" connect @slide="setDistance" :step="25" :handles="[filters['min_tarif'], filters['max_tarif']]" :range ="{'min':0,'max':500}"/>
          </b-col>
          <b-col cols="12" class="mt-5">
            <span class="font-weight-bold">Nombre d’étoiles</span><br>
            <b-button class="mt-2 ml-1" variant="outline-primary" size="sm" v-for="e in 5" :key="e" :class="filters['mutuelle.rating'].includes(e) ? 'active' : ''" @click="toggleRating(e)"><span style="font-size:1.3rem !important;">{{e}}</span> <i class="fas fa-star"></i></b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="9">
      <b-card class="card-search card-shadow mb-4 mt-4">
        <b-row>
          <b-col md="7">
            <p class="h3 text-custom-primary mt-2"><strong>Houra ! Vous avez {{datatable.totalRow}} offres vous correspondant !</strong></p>
          </b-col>
          <b-col md="5" class="text-right">
            <span class="h5 font-weight-bold">Triez par</span>
            <b-dropdown no-caret variant="outline-primary" :text="`${sort.key} du ${sort.value === 'DESC' ? '+ au -' : '- au +'}`" class="ml-3">
              <b-dropdown-item @click="sortChanged('tarif', 'DESC')">Tarif du + au -</b-dropdown-item>
              <b-dropdown-item @click="sortChanged('tarif', 'ASC')">Tarif du - au +</b-dropdown-item>
              <b-dropdown-item @click="sortChanged('mutuelle.rating', 'DESC')">Note du + au -</b-dropdown-item>
              <b-dropdown-item @click="sortChanged('mutuelle.rating', 'ASC')">Note du - au +</b-dropdown-item>
            </b-dropdown>
            <b-button-group class="ml-1">
              <b-button variant="outline-primary" @click="view = 'list'" :class="view === 'list' ? 'active' : ''"><i class="fas fa-list"></i></b-button>
              <b-button variant="outline-primary" @click="view = 'grid'" :class="view === 'grid' ? 'active' : ''"><i class="fas fa-th-large"></i></b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-card>
      <div v-if="view === 'list'">
        <DetailList v-for="(q,i) in datatable.data" :key="i" :data="q"></DetailList>
      </div>
      <b-row v-if="view === 'grid'" >
        <b-col  md="6" v-for="(q,i) in datatable.data" :key="i">
          <DetailGrid :data="q" />
        </b-col>
      </b-row>
      <b-pagination size="lg" align="center" v-model="datatable.currentPage" @change="changePage" :total-rows="datatable.totalRow" :per-page="datatable.limitPage" aria-controls="itemList"></b-pagination>
    </b-col>
  </b-row>
</template>
<script>
import veeno from 'veeno';
import 'nouislider/distribute/nouislider.min.css';
//import Input from "@/components/InputForm";
import DetailList from "@/components/DetailList";
import DetailGrid from "@/components/DetailGrid";
export default {
  name: "",
  components: {
    veeno, DetailList, DetailGrid
  },
  data: () => ({
    view:"list",
    filters:{
      "mutuelle.rating":[],
      "mutuelle_id":[],
      "min_tarif":0,
      "max_tarif":500
    },
    datatable:{
      currentPage: 1,
      limitPage: 10,
      totalRow: 0,
      data: []
    },
    sort:{
      key: 'tarif',
      value: 'DESC'
    },
    params:false
  }),
  watch:{
    'filters':{
      deep:true,
      handler(){
        this.datatableInit();
      }
    }
  },
  methods: {
    datatableInit(){
      var params = {
        filters: this.filters,
        sort: this.sort,
        limit: this.datatable.limitPage,
        page: this.datatable.currentPage
      }
      this.$api.ajax('https://api.laf.jg.app/d/quote/'+this.$route.params.uuid, params,(res) => {
        this.datatable = res;
      });
    },
    sortChanged(key,value){
      this.sort = {
        key: key,
        value: value
      };
      this.datatable.currentPage = 1;
      this.datatable.data = [];
      this.datatableInit();
    },
    changePage(e){
      this.datatable.currentPage = e;
      this.datatableInit();
    },
    toggleRating(e){
      if(this.filters['mutuelle.rating'].includes(e)){
        this.filters['mutuelle.rating'] = this.filters['mutuelle.rating'].filter(a => a != e)
      }else{
        this.filters['mutuelle.rating'].push(e);
      }
    },
    setDistance(e){
      this.filters["min_tarif"] = parseInt(e.values[0])
      this.filters["max_tarif"] = parseInt(e.values[1])
    },
  },
  beforeMount() {
    this.$api.ajax('https://api.laf.jg.app/d/params', null, res => {
      if(res.status === true){
        this.params = res.data;
      }
    })
  },
  mounted() {
    this.datatableInit()
  }
}
</script>
<style scoped>
.sous-titre {
  font-weight: 300;
  font-size: 25px !important;
  color: #fff;
}
.text-custom-primary{
  color: #3b8aff !important;
  font-weight: 300;
}
.sticky-top.filter{
  top: 13rem !important
}
</style>
